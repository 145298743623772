import React, { useState, useEffect, useRef  } from "react"
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled from "styled-components"
import breakpoint from "./vars"
import Typewriter from 'typewriter-effect';
import VanillaTilt from 'vanilla-tilt';
import { FaCode } from "@react-icons/all-files/fa/FaCode";
import { FaPencilRuler } from "@react-icons/all-files/fa/FaPencilRuler";
import { FaRocket } from "@react-icons/all-files/fa/FaRocket";
import { FaHome } from "@react-icons/all-files/fa/FaHome";
import { Title } from "./common";
import { keyframes } from "styled-components";


function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}


const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  align-items: start;
  max-width: 80vw;
  @media only screen and ${breakpoint.device.sm}{ 
    max-width: var(--size-content);
  }
  @media only screen and ${breakpoint.device.lg}{  
    align-items: center;
  }
`

const HeroContent = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 2rem;
  margin-top: 1em;
  text-align: center;
  position:relative;
  @media only screen and ${breakpoint.device.lg}{  
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    margin-top: 0;
  }
`

const Welcome = styled.p`
  font-family: var(--font-mono);
  margin-bottom:0.25em;
  margin-top:1rem;
  @media only screen and ${breakpoint.device.lg}{  
    margin-top:0;
`
const Name = styled.h1`
  margin: 0 0 0.125em;
  padding: 0;
  font-size: 3rem;
  font-weight: 600;
  font-family: 'Inter var', sans;
  color:var(--color-primary);
  @media only screen and ${breakpoint.device.sm}{  
    font-size: 4rem;
  }
`
const Subname = styled.h2`
  margin: 0 0 12px 0;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: 'Inter var', sans;
  color:var(--color-secondary);
  height:3em;
`

const TiltWrapper = styled.div`

-moz-perspective: 5000;
-webkit-perspective: 5000;
`

const HeroIconWrapper = styled.div`
  height:25vh;
  min-height:150px;
  aspect-ratio : 1 / 1;
  margin-bottom:1em;
  @media only screen and ${breakpoint.device.sm}{  
    max-height:500px;
    margin-bottom:0;
    min-height:250px;
  }
  @media only screen and ${breakpoint.device.lg}{  
    height:400px;   
  }
  -webkit-transform: translateZ(-1000px);
  -webkit-perspective-origin: 100% 100% none;
`
const HeroIcon = styled.div`
  font-size:6rem;
  color:var(--color-accent-1);
  border: 1rem var(--color-accent-1) solid;
  background-color: var(--color-accent-2);
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  -webkit-box-shadow: 0 1rem 20px 0px #454545; 
  box-shadow: 0 2rem 2rem -2rem #454545;
  border-radius: 2rem;
  box-sizing: border-box;
  overflow:hidden;
  position:relative;
  margin-top: 4rem;
  @media only screen and ${breakpoint.device.sm}{ 
    font-size:10em;
  }
  @media only screen and ${breakpoint.device.lg}{  
    font-size:16em;
    margin-top:0;
  }
`
const arrowAnimation = keyframes`
 0%, 100% { transform: translateY(0)}
 50%{ transform: translateY(20px)}
`

const Arrow = styled.div`
  text-align:center;
  color: var(--color-text);

  margin-top: 2em;
  margin-bottom: -2em;
  @media only screen and (max-height: 500px){  
    margin-top: 0;
    margin-bottom: 0;
  }
  @media only screen and ${breakpoint.device.lg}{  
    margin-top: 5em;
    margin-bottom: -5em;
  }
  animation-name: ${arrowAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  > svg{
    height:2.5em;
    width: 2.5em;
  }

  position:absolute;
  bottom:0;
  left:0;
  width:100%;

`


const Left = props => {

  return (
  <div>
    <Welcome>Hey, my name is</Welcome>
    <Name>Marco Schlichting</Name>
    <Subname><Typewriter
    onInit={(typewriter) => {
      typewriter
        .typeString('& I\'m a full stack web developer')
        .callFunction(() => {
          props.setCurrentIcon('code')
        })
        .pauseFor(2000)
        .deleteAll(10)
        .typeString('& I\'m a productivity nerd')
        .callFunction(() => {
          props.setCurrentIcon('pen')
        })
        .pauseFor(2000)
        .deleteAll(10)
        .typeString('& I\'m a tech junkie')
        .callFunction(() => {
          props.setCurrentIcon('rocket')
        })
        .pauseFor(2000)
        .deleteAll(10)
        .typeString('& I\'m a smart home enthusiast')
        .callFunction(() => {
          props.setCurrentIcon('home')
        })
        .pauseFor(2000)
        .deleteAll(10)
        .start();
    }}
  options={{
    delay: 25,
    cursor: '_',
    loop: true,
  }}
/>
    </Subname>
    <Arrow>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3" />
      </svg>
    </Arrow>
  </div>
)}

const Right = props => {
  const icon = props.icon
  
  return (
    <TiltWrapper>
    <Tilt options={{
      scale: 1.00,
      speed: 6250,
      max: 30,
      reset: false,
      startX: -30, //Math.floor(Math.random() * 60) - 30,
      startY: 30, //Math.floor(Math.random() * 60) - 30,
    }}>
      <HeroIconWrapper>
        <HeroIcon>
          <CSSTransition in={true} appear={true} timeout={1350} classNames="fade">
            <TransitionGroup>
              <CSSTransition key={icon} timeout={1350} classNames="fade">
                <div style={{position: "absolute", width:'100%', height:'100%', display:'flex', alignItems: 'center', justifyContent: 'center'}}>
                  {icon==='code' && <FaCode/>}
                  {icon==='pen' && <FaPencilRuler/>}
                  {icon==='rocket' && <FaRocket/>}
                  {icon==='home' && <FaHome/>}
                </div>
              </CSSTransition>
            </TransitionGroup>
          </CSSTransition>
        </HeroIcon>
      </HeroIconWrapper>
    </Tilt>
    </TiltWrapper>
  )
}

export default function Hero(props) {
  const [state, setState] = useState({
    icon: 'addresscard',
});
  return (
    <>
    <div style={{height: '0', overflow: 'hidden', opacity:0}}>
      <Title setActiveMenuItem={props.setActiveMenuItem} ></Title>
    </div>
    <Container>
      <HeroContent>
        <Left setCurrentIcon={(val) => setState({icon:val})}/>
        <Right icon={state.icon}/>
      </HeroContent>
    </Container>
    </>
  )
}