import React, {useState} from "react"
import styled from "styled-components";
import VisibilitySensor from 'react-visibility-sensor'
import Typewriter from 'typewriter-effect';
import breakpoint from "./vars"
import { FaExternalLinkAlt } from "@react-icons/all-files/fa/FaExternalLinkAlt";
import { FaGithub } from "@react-icons/all-files/fa/FaGithub";

import PathfindingFX from "./pathfindingfxdemo";
import { AnchorLink } from "gatsby-plugin-anchor-links";


const StyledContainer = styled.div`
  margin-inline: auto;
  margin-top:6em;
  min-height: 50vh;
  max-width: 80vw;
  @media only screen and ${breakpoint.device.sm}{ 
    max-width: var(--size-content);
  }
`


export function Container(props) {
    return (
        <StyledContainer id={props.id}>
            {props.children}
        </StyledContainer>
    )
}


const StyledTitle = styled.h1`
    font-family: 'Inter var', sans;
    text-align:center;
    color: var(--color-primary);
    min-height:2.5rem;
`

export function Title(props) {
    const [state, setState] = useState({
      isVisible: false,
    });

    return (
        <VisibilitySensor
            partialVisibility
            onChange={(isVisible) => {
                setState({isVisible: isVisible})
                if(isVisible)
                    props.setActiveMenuItem()
            }}
        >
            <StyledTitle>
                {state.isVisible ? 
                    <Typewriter
                        onInit={(typewriter) => {
                            if(props.type){
                                props.type(typewriter);
                            } else {
                                typewriter
                                .typeString(props.children)
                                .start();
                            }
                        }}
                        options={{
                            delay: 25,
                            cursor: '_',
                        }}
                    /> 
                : null}
            </StyledTitle>
        </VisibilitySensor>
    )
}

const StyledSubtitle = styled.h2`
    font-family: 'Inter var', sans;
    text-align:center;
    color: var(--color-secondary);
    min-height:2.5rem;
    text-transform: uppercase;
    font-size:1.25em;
    border-bottom 4px solid var(--color-accent-1);
`


export function Subtitle(props) {
    return (
            <StyledSubtitle>
                {props.children}
            </StyledSubtitle>
    )
}

export const Paragraph = styled.div`
    font-family: sans-serif;
    text-align:justify;
    @media only screen and ${breakpoint.device.sm}{ 
        text-align:left;
    }
`

// GRID CONTAINER
const StyledGridContainer = styled.div`
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    @media only screen and ${breakpoint.device.sm}{ 
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media only screen and ${breakpoint.device.lg}{ 
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
`

export function GridContainer(props){
    return (
        <StyledGridContainer>
            {props.children}
        </StyledGridContainer>
    )
}

// CARD

const StyledCard = styled.div`
    position:relative;
  display:flex;
  flex-direction: column;
  gap: 0.875em;
  padding:1rem;
  border-radius: 0.875rem;
  > h2{
    font-size: 1.125rem;
    font-family: 'Inter var', sans;
    margin:0;
    padding:0;
  }
  > p{
    font-size: 0.875rem;
    font-family: var(--font-sans);
    text-align: justify;
    margin:0;
    padding:0;
  }
  transition: transform 350ms ease-in-out, background-color 350ms ease-in-out, box-shadow 350ms ease-in-out;
  &:hover{
    transform: translateY(-0.25em)!important;
    background-color: rgba(255,255,255,0.5);
    -webkit-box-shadow: 0 1rem 20px 0px #454545; 
    box-shadow: 0 2rem 2rem -2rem #454545;
  }
  &:nth-child(2){
    transform: translateY(0em);
    background-color: rgba(255,255,255,0.5);
  }
`

const StyledCardTitle = styled.div`
    display:flex;
    align-items:center;
    justify-content: space-between;
    >h3{
        padding:0;
        margin:0;
    }
    >div{
        display:flex;
        gap:1em;
        > a{
            color: var(--color-text);
            transition: color 100ms ease-in-out;
            &:hover{
                color: var(--color-primary);
            }
        }
    }
`


const StyledStatus = styled.div`
  display:inline-block;
  color: white;
  font-family: 'Inter var', sans;
  font-size:0.875rem;
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem 0.25rem;
`

const StyledTags = styled.div`
pointer-events:none;
  display: flex;
  gap:0.25rem;
  >span{
    pointer-events: none;
    color: var(--color-primary);
    font-family: var(--font-mono);
    /*background-color: var(--color-secondary);*/
    padding: 0.125rem 0.25rem;
    font-size: 0.875rem;
    border-radius: 0.5rem;
    &:first-child{
      padding-left:0;
    }
  }
`

export function Card(props){
    const {title, status, description, tags, url, github} = props;
    return (
        <StyledCard>
            <StyledCardTitle>
                <h3>{title}</h3>
                <div>
                    {url && url != "#" && <a href={url} target="_blank" rel="noopener"><FaExternalLinkAlt/></a>}
                    {url && url == "#" && <AnchorLink to="#top"><FaExternalLinkAlt/></AnchorLink>}
                    {github && <a href={github} target="_blank" rel="noopener"><FaGithub/></a>}
                </div>
            </StyledCardTitle>
            <div>

            {status && <Status status={status} />}
            </div>
            <p dangerouslySetInnerHTML={{__html: description}}></p>

            {tags && <StyledTags>{tags.map((tag, key) => <span key={key}>{tag}</span>)}</StyledTags>}
            {title=='Pathfinding FX' && <PathfindingFX/>}

        </StyledCard>
    )
}

export function Status(props) {
    return (
      <StyledStatus style={{
        backgroundColor:props.status==='paused'?'#7882A4': ( props.status==='released'?'#90C8AC': '#98A8F8')
        }}>
        {props.status}
      </StyledStatus>
  )}