import React from "react"
import styled from "styled-components"
import { Container, Title } from "./common";
import { FaGithub } from "@react-icons/all-files/fa/FaGithub";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaCodepen } from "@react-icons/all-files/fa/FaCodepen";
import { FaNpm } from "@react-icons/all-files/fa/FaNpm"



const StyledCenterText = styled.p`
  text-align: center;
  margin-inline: auto;
`

const StyledSocials = styled.div`
  margin: 1rem 0;
  display:flex;
  gap:2rem;
  justify-content: center;
  > a{
    font-size: 1.25rem;
    transition: transform 200ms ease-in-out, color 100ms ease-in-out;
    color:var(--color-text);
    &:hover{
      transform: translateY(-0.125em);
      color: var(--color-primary);
    }
  }
`
export default function Contact(props) {

  return (
    <Container id="contact">
      <Title setActiveMenuItem={props.setActiveMenuItem}>04. Contact</Title>
      <StyledCenterText>Although I'm not looking for any new opportunies, I'd love to hear from you! <br/> 
      Feel free to contact me somewhere here: </StyledCenterText>
      
      <StyledSocials>
        <a href="https://twitter.com/MarcoPlainly" target="_blank" rel="noreferrer">
          <FaTwitter/>
        </a>
        <a href="https://www.instagram.com/marcoplainly/" target="_blank" rel="noreferrer">
          <FaInstagram/>
        </a>
        <a href="https://github.com/MarcoPlain" target="_blank" rel="noreferrer">
          <FaGithub/>
        </a>
        <a href="https://codepen.io/MarcoPlain" target="_blank" rel="noreferrer">
          <FaCodepen/>
        </a>
        <a href="https://www.npmjs.com/~marcoplain" target="_blank" rel="noreferrer">
          <FaNpm/>
        </a>
      </StyledSocials>
    </Container>
  )
}