import React from "react"
import styled from "styled-components"
import breakpoint from "./vars"
import { Container } from "./common";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { FaGithub } from "@react-icons/all-files/fa/FaGithub";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaCodepen } from "@react-icons/all-files/fa/FaCodepen";

const StyledHeading = styled.div`
  position: fixed;
  width: 100vw;
  background-color: var(--color-accent-2);
  padding: 0.5em 1em;
  z-index: 99;


  >div{
    display:flex;
    align-items: center;

      justify-content: space-between;
    min-height: unset;
    margin-top:unset;
    >a{
      text-decoration: none;
    }
  }
`

const StyledLogo = styled.h1`
  font-size:1.175rem;
  color: var(--color-primary);
  font-weight: 600;
  font-family: 'Inter var', sans;
  margin:0;
`



const StyledNav = styled.nav`
  counter-reset: item 0;


  display: none;

  @media only screen and ${breakpoint.device.sm}{  
    display:block;
  }
  > ol{
    padding:0;
    margin:0;
    display:flex;
    justify-content: center;
    align-items: center;
    height:2rem;
    gap:2rem;
    list-style: none;
    > li{
      padding:0;
      margin:0;
      color: var(--color-text);
      font-size:0.75rem;
      counter-increment: item 1;
      &:before {
        content: "0" counter(item) ".";
      }
      > a {
        font-family: var(--font-sans);
        font-size: 0.875rem;
        color: var(--color-text);
        font-weight: bold;
        text-decoration:none;
        transition: color 0.15s ease-in-out;
        &:hover&:not(:focus), &.active {
          color: var(--color-primary);
        }
      }
    }
  }
`

const StyledMobileMenuToggle = styled.button`
  border:none;
  background:transparent;
  z-index: 9999;
  transition: color 0.3s ease-in-out;
  color: var(--color-text);
  >svg{
    width:2.25em;
    height:2.25em;
    color: var(--color-text);
  }
  @media only screen and ${breakpoint.device.sm}{  
    display:none;
  }
  &.active{
    color: var(--color-primary);
  }
`

const StyledMobileMenu = styled.div`
  @media only screen and ${breakpoint.device.sm}{  
    display:none;
  }
  position:fixed;
  top:0;
  right:0;
  height:100vh;
  width:60vw;
  background-color: var(--color-accent-1);
  padding: 5em 2em;
  transition: margin 0.3s ease-in-out;
  margin-right:-60vw;
  z-index:9998;
  >div:first-child{
    display:none;
    position: absolute;
    width:10000px;
    height:10000px;
    top:-1000px;
    left:-1000px;
    background-color: var(--color-accent-2);
    opacity: 0;
    z-index:-1;
  }
  &.active{
    margin-right:0;
    >div:first-child{
      display:block;
      opacity: 0.5;
    }
  }
  > ol{
    padding:0;
    margin:0;
    display:flex;
    flex-direction: column;
    gap:2rem;
    list-style: none;
    > li{
      padding:0;
      margin:0;
      color: var(--color-text);
      font-size:0.75rem;
      counter-increment: item 1;
      &:before {
        content: "0" counter(item) ".";
      }
      > a {
        font-family: var(--font-sans);
        font-size: 0.875rem;
        color: var(--color-text);
        font-weight: bold;
        text-decoration:none;
        transition: color 0.15s ease-in-out;
        &:hover&:not(:focus), &.active {
          color: var(--color-primary);
        }
      }
    }
  }
`

const StyledSocials = styled.div`
  margin: 1rem 0;
  display:flex;
  flex-direction: column;
  position:absolute;
  bottom:8em;
  gap:2rem;
  > a{
    color: var(--color-text);
    &:hover{
      color: var(--color-primary);
    }
    font-size: 1.25rem;
  }
`


export default function Navigation(props) {

  const [state, setState] = React.useState({
    showMobileMenu: false,
  });

  const links = [
    {
      name: "About",
      id: "/#about",
    },
    {
      name: "Projects",
      id: "/#projects",
    },
    {
      name: "Skills",
      id: "/#skills",
    },
    {
      name: "Contact",
      id: "/#contact",
    },
  ]

  return (
    <StyledHeading>
      <Container>
          <a href="/"><StyledLogo>Marco Plain</StyledLogo></a>
        <StyledNav>
          <ol>
            {links.map((link, key) => (<li key={link.id}> <AnchorLink to={link.id} className={props.activeMenuItem === key+1 ? 'active' : ''}> {link.name} </AnchorLink> </li>))}
          </ol>
        </StyledNav>
        <StyledMobileMenuToggle onClick={ () => setState({showMobileMenu: !state.showMobileMenu})}  className={state.showMobileMenu?'active':''}>
          {!state.showMobileMenu && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
          }
          {state.showMobileMenu &&  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
          }
        </StyledMobileMenuToggle>
        <StyledMobileMenu className={state.showMobileMenu?'active':''}>
          <div role="presentation" onClick={ () => setState({showMobileMenu: !state.showMobileMenu})} onKeyDown={ () => setState({showMobileMenu: !state.showMobileMenu})}></div>
          <ol>
            {links.map((link, key) => (<li key={link.id}> <AnchorLink onAnchorLinkClick={ () => setState({showMobileMenu: !state.showMobileMenu})} to={link.id} className={props.activeMenuItem === key+1 ? 'active' : ''}> {link.name} </AnchorLink> </li>))}
          </ol>
          <StyledSocials>
        <a href="https://twitter.com/MarcoPlainly" target="_blank" rel="noreferrer">
          <FaTwitter/>
        </a>
        <a href="https://www.instagram.com/marcoplainly/" target="_blank" rel="noreferrer">
          <FaInstagram/>
        </a>
        <a href="https://github.com/MarcoPlain" target="_blank" rel="noreferrer">
          <FaGithub/>
        </a>
        <a href="https://codepen.io/MarcoPlain" target="_blank" rel="noreferrer">
          <FaCodepen/>
        </a>
      </StyledSocials>
        </StyledMobileMenu>
      </Container>
    </StyledHeading>
  )
}