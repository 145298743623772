import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { Card, Container, GridContainer, Title } from "./common"

export default function Projects(props) {
  const data = useStaticQuery(graphql`
    query projectsQuery {
      allProjectsJson(sort: { fields: date, order: DESC }) {
        nodes {
          name
          description
          status
          stack
          date
          url
          github
        }
      }
    }
  `)

  return (
    <Container id="projects">
      <Title
        setActiveMenuItem={props.setActiveMenuItem}
        type={typewriter =>
          typewriter
            .typeString(
              "02. Projects"
            ) /*.typeString('02. Stuff I\'ve been working on')/*.pauseFor(2500).typeString('.').pauseFor(2500).typeString('.').pauseFor(2500).typeString('.').pauseFor(5000).typeString('<span style="font-size:60%">Come on, just check them out yet!</span>')*/
            .start()
        }
      />

      <GridContainer>
        <TransitionGroup component={null}>
          {data.allProjectsJson.nodes.map((node, key) => (
            <CSSTransition key={key} timeout={0} classNames="fade">
              <Card
                title={node.name}
                description={node.description}
                tags={node.stack}
                status={node.status}
                url={node.url}
                github={node.github}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </GridContainer>
    </Container>
  )
}
