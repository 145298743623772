import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components"
import { Container, GridContainer, Subtitle, Title } from "./common";

const StyledSkillList = styled.ol`
  list-style:none;
  counter-reset:item 0;
  >li{
    counter-increment: item 1;
    &:before{
      content: "0" counter(item) ". ";
      font-size: 0.75rem;
    }
  }
`


export default function Skills(props) {
  const columns = ['languages', 'frameworks', 'tools']
  const data = useStaticQuery(graphql`
    query skillsQuery {
      allSkillsJson {
        nodes {
          name
          type
        }
      }
    }
  `);

  return (
    <Container id="skills">
      <Title setActiveMenuItem={props.setActiveMenuItem}>03. Skills</Title>

      <GridContainer>
        {columns.map(column=>(
          <div key={column}>
            <Subtitle>{column}</Subtitle>
            <StyledSkillList>
              {data.allSkillsJson.nodes.filter(node=>node.type===column).map((node, key)=> <li key={key}>{node.name}</li> )}
            </StyledSkillList>
            </div>
        ))}
      </GridContainer>
      
    </Container>
  )
}