import React from "react"
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components"

import breakpoint from "./vars"

import {Title, Paragraph, Container, GridContainer} from "./common"

const ImageWrapper = styled.div`
  text-align: center;
  grid-column: span 2 / span 2;
  margin-bottom:4em;

  @media only screen and ${breakpoint.device.lg}{  
    width: 250px;
    height:250px;
    grid-column: span 1 / span 1;
    margin-bottom:0em;
  
  }
  * {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;       
    border-radius: 50%;
  }
  >div{

    -moz-background-clip: padding;  
    -webkit-background-clip: padding-box;  
    background-clip: padding-box;

    overflow: hidden;
    -webkit-box-shadow: 0 1rem 20px 0px #454545; 
    box-shadow: 0 2rem 2rem -2rem #454545;
  }
`

export default function About(props) {
  return (
    <Container id="about">

      <Title setActiveMenuItem={props.setActiveMenuItem}>
        01. About me
      </Title>
      <GridContainer style={{	alignItems: 'center'}}>
        <div style={{gridColumn: 'span 2 / span 2', padding: "1em 1em 1em 0"}}>
          <Paragraph>
            <p>Hello! My name is Marco and I enjoy writing code. 
            Things that require a power source and have some display are my working tools.</p>

            <p>Everything began when I was in school and hacked together Warcraft 3 Custom Maps and built small websites to advertise them.</p>

            <p>Today I am working full-time at a german IT agency, but <b>to bring back the hobby into my profession I started to create this website</b> and am planning on creating more!</p>
          </Paragraph>
        </div>
        <ImageWrapper>
          <StaticImage src="../images/me-edited-300x300.jpeg" width={250} height={250} alt="An image of myself in Paris"/>
        </ImageWrapper>
      </GridContainer>
    </Container>
  )
}