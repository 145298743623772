import * as React from "react"
import styled from "styled-components"
import Navigation from "../components/navigation"
import Hero from "../components/hero"
import About from "../components/about"
import Projects from "../components/projects"
import Skills from "../components/skills"
import Contact from "../components/contact"
import Footer from "../components/footer"

const Main = styled.main`
  color:var(--color-text);
  position:relative;
`

const IndexPage = () => {

  const [state, setState] = React.useState({
    activeMenuItem: 0,
  });

  React.useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      initMatomo()
    }
  }, []);

  const initMatomo = () => {
    var _paq = window._paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    _paq.push(['enableHeartBeatTimer']);
    _paq.push(['requireCookieConsent']);
    (function() {
      var u="//stats.marcoplain.com/";
      _paq.push(['setTrackerUrl', u+'matomo.php']);
      _paq.push(['setSiteId', '1']);
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    })();
  }

  const trackScrollPosition = (name) => {
    if(window._paq)
      window._paq.push(['trackEvent', 'Position', 'Scroll', name])
  }

  return (
    <>
    <Main id="top">
      <Navigation activeMenuItem={state.activeMenuItem}/>
      <Hero setActiveMenuItem={() => [setState({activeMenuItem: 0}), trackScrollPosition('Hero')]}/>
      <About setActiveMenuItem={() => [setState({activeMenuItem: 1}), trackScrollPosition('About')]}/>
      <Projects setActiveMenuItem={() => [setState({activeMenuItem: 2}), trackScrollPosition('Projects')]}/>
      <Skills setActiveMenuItem={() => [setState({activeMenuItem: 3}), trackScrollPosition('Skills')]}/>
      <Contact setActiveMenuItem={() => [setState({activeMenuItem: 4}), trackScrollPosition('Contact')]}/>
    <Footer/>
    </Main>
    </>
  )
}

export default IndexPage

export const Head = () => <title>Marco Plain | Full-Stack-Developer</title>