import React, { useEffect } from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"

const StyledPFX = styled.div`
min-height:50px;
  > div {
    position: absolute;
    bottom: 0.5em;
    right: 0.5em;
    z-index: -1;
    width: 120px;
    height: 120px;
    border-radius: 0 0 0.875rem 0;
    overflow: hidden;
  }
`
export default function PathfindingFXDemo() {
  useEffect(() => {
    // componentDidMount() {}
    const externalScript = document.createElement("script")
    externalScript.src = "pathfinding-fx-example.js"
    externalScript.async = true
    document.head.append(externalScript)

    // componentWillUnmount() {}
    return () => {
      externalScript.remove()
    }
  }, [])
  return (
    <>
      <Helmet>
        <script src="pathfinding-fx.js"/>
      </Helmet>
      <a
        href="https://marcoplain.github.io/pathfinding-fx.js/"
        target="_blank"
        rel="noopener"
      >
        <StyledPFX>
          <div>
            <div id="pfx"></div>
          </div>
        </StyledPFX>
      </a>
    </>
  )
}
