import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  background-color: var(--color-accent-1);
  height: 5em;
  text-align: center;
  color: var(--color-code);
  font-size:0.875rem;
  padding:0 1em;
`

export default function Footer() {

  return (
    <Container>
      <div>
        Designed & Developed by Marco Schlichting · 2023 · v1.1
      </div>
    </Container>
  )
}